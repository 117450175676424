* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Satoshi', sans-serif;
  background: #f94d17;
  font-weight: 500;
}

#root {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

//container
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    min-height: 100svh;
  }
}

a:focus,
button:focus {
  outline: unset;
}

//login
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #f2f2f2;
    margin-top: auto;
  }
  &-logo {
    max-height: 57px;
    width: auto;
    margin-top: auto;
  }
  &-form {
    position: relative;
    margin-top: auto;
    display: flex;
    max-width: 390px;
    width: 100%;
    background: #f2f3f6;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 30px 79px;
    &-biglogo {
      position: absolute;
      left: -25px;
      top: -45%;
      width: 290px;
    }
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 14px;
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
      }
      input {
        line-height: 17px;
        background: #fbfcfe;
        color: #8585ad;
        border: 0.75px solid transparent;
        box-shadow: 0px 4px 15px rgba(225, 229, 240, 0.3);
        border-radius: 6px;
        padding: 17px 20px;
        &.error {
          border-color: #ff2121;
        }
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
      .handingForm {
        position: relative;
        margin-top: 56px;
        margin-bottom: 100px;
        #errorMsg {
          position: absolute;
          left: 0;
          top: -44px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-decoration-line: underline;
          color: #ff2121;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 100%;
          background: #f94d17;
          font-size: 17px;
          font-weight: 600;
          color: #fff;
          padding: 15px;
          border-radius: 38px;
          outline: none;
          border: none;
        }
      }
    }
  }
}

//footer
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #353535;
  border-radius: 16px 16px 0px 0px;
  padding: 34px 30px;
  color: rgba(242, 242, 242, 0.7);
  font-size: 9px;
  line-height: 11px;
  font-style: normal;
  font-weight: 300;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  & > * {
    display: flex;
    gap: 7px;
  }
}

// Content
.content {
  position: absolute;
  bottom: 58px;
  background: #f2f3f7;
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: calc(100% - 284px);
  @media (max-height: 680px) {
    height: calc(100% - 219px);
  }
}

//ovrlay
.modal-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(53, 53, 53, 0.4);
  z-index: 9;
  &.show {
    display: block;
  }
}
