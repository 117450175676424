.navbar {
  margin-top: 20px;
  margin-bottom: 60px;
  @media (max-height: 680px) {
    margin-bottom: 30px;
  }
  .menu {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    z-index: 9;

    button {
      border: none;
      background: transparent;
    }

    h2 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.6;
      text-align: center;
      color: #ffffff;
    }

    .hamburger {
      margin-left: auto;
    }
  }

  .hero {
    position: relative;
    margin: 40px 30px 0;
    @media (max-height: 680px) {
      margin: 20px 15px 0;
    }

    h2 {
      position: relative;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 1.12;
      letter-spacing: -0.02em;
      color: #ffffff;
      @media (max-height: 680px) {
        font-size: 22px;
      }
      span {
        font-weight: 300;
      }
    }

    img {
      position: absolute;
      right: 0;
      top: -35px;
    }
  }
}
