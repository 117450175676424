.meal-popup {
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 372px;
  width: calc(100% - 36px);
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  z-index: 99;
  &.show {
    display: block;
  }
  p {
    color: #858585;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.06;
    margin-bottom: 20px;
    text-align: center;
  }
  span {
    display: block;
    width: calc(100% - 44px);
    margin: 20px auto;
    border: 1px solid #e7e7e7;
  }

  .btn-main {
    padding-top: 12px;
    padding-bottom: 12px;
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}
