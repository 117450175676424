.overlay {
  background: rgba(53, 53, 53, 0.4);
  z-index: 40;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.success-modal {
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 372px;
  width: calc(100% - 36px);
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  z-index: 99;
  &.show {
    display: block;
  }
  img {
    display: block;
    width: auto;
    height: 50px;
    margin: 0 auto 18px;
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.06;
    color: #424242;
    text-align: center;
    margin-bottom: 18px;
  }
  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.06;
    text-align: center;
    color: #858585;
    margin-bottom: 18px;
  }
  .btn-main {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
