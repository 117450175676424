.shift-modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 372px;
  width: calc(100% - 36px);
  max-height: calc(100% - 16px);
  height: auto;
  background: #f2f3f6;
  border-radius: 6px;
  padding: 30px 0;
  z-index: 99;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.show {
    display: block;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #424242;
    text-align: left;
    padding: 0 27px 23px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 20px;
    @media (max-height: 680px) {
      padding-bottom: 20px;
    }
  }
  &__title-confirm {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #424242;
    text-align: center;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 30px;
    &:first-of-type {
      padding-bottom: 16px;
    }
    .shift-box,
    .group-box,
    .location-box {
      background: #fff;
      border-radius: 6px;
      &__info {
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #424242;
        padding: 14px;
      }
      ul {
        list-style: none;
        &.hidden {
          display: none;
        }
        li {
          cursor: pointer;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #424242;
          padding: 16px;
          &:hover,
          &.active {
            background: #dee0e7;
          }
        }
      }
    }
    .modal-btns {
      margin-top: 20px;
      .btn-main {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .btn-main:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}
