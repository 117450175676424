.overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: #35353566;

  .modal {
    background: #ffffff;
    border-bottom: 1.07228px solid #e7e7e7;
    border-radius: 6px;

    position: absolute;
    background: #ffffff;

    left: 50%;
    transform: translateX(-50%);

    width: calc(100% - 32px);
    margin: 0 auto;

    &-close {
      top: 15%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .user-info {
        background: #f2f3f6;
        border: 1px solid #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;

        img {
          margin-right: 3.3px;
        }
      }
    }

    .close-btn {
      background: transparent;
      border: none;

      padding: 17px 0;
      display: flex;
      justify-content: center;
    }

    .close-btn:nth-child(2) {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 106%;

      display: flex;
      align-items: center;

      color: #424242;

      border-bottom: 1px solid #e6e6e6;
    }
    .close-btn:nth-child(3) {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 106%;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;

      /* Narandzasta - Main */

      color: #f94d17;
    }
  }
}
