.btn-main {
  width: 100%;

  font-family: inherit;

  border: none;
  padding: 8px 42px;

  border-radius: 38px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
  cursor: pointer;

  &.transparent {
    background: transparent !important;
  }
  &.giveup {
    background: transparent !important;
    color: #959aa6 !important;
  }
  &.red {
    background: #eb5948 !important;
  }
  &.orange {
    background: #f94d17 !important;
  }
  &.grey {
    background: #f2f3f6 !important;
    color: #5e626c !important;
  }

  &.grey-modal {
    background: #d5d8e2;
    color: #5e626c !important;
  }

  &.blue {
    background: #5c6fd4 !important;
  }
}
