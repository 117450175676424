.menu {
  position: relative;
  height: 100%;
  > h3,
  div > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: -0.01em;

    color: #515151;
    margin-bottom: 16px;
    padding: 20px;
    @media (max-height: 680px) {
      margin-bottom: 0;
    }
  }
  div > h3 {
    padding: 20px 0;
  }
}

.days-container {
  position: absolute;
  width: 100%;
  bottom: 22px;
  top: 80px;
  overflow-y: scroll;
  padding: 0 20px 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.archive {
    top: 2px;
  }
  div:nth-child(2) h3 {
    margin-top: 20px;
  }
  @media (max-height: 680px) {
    top: 64px;
  }
}

.days {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;

  .day {
    max-width: 240px;
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    @media (max-width: 791px) {
      max-width: 100%;
      width: calc(50% - 8px);
      &:last-of-type {
        margin-right: auto;
      }
    }

    &__header {
      padding: 10px 14px;
      border-bottom: 1.07228px solid #e7e7e7;
      border-radius: 6px;

      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        /* identical to box height */

        color: #424242;
      }
      h4::first-letter {
        text-transform: uppercase;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        color: #858585;
      }
      span::first-letter {
        text-transform: capitalize;
      }
    }
    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 13px 11px;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        color: #858585;
      }

      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 39px;
        text-align: center;
        color: #424242;
        @media (max-height: 680px) {
          line-height: 24px;
        }
      }
    }
  }
}

.selector {
  position: fixed;
  bottom: 0;
  width: 100%;

  background: #353535;
  border-radius: 16px 16px 0px 0px;
  padding: 22px 17px;

  &__box {
    display: flex;
    justify-content: space-between;
    background: #434343;
    border-radius: 38px;
  }
}
