.content:has(.order) {
  bottom: 0;
  height: calc(100% - 226px);
  @media (max-height: 680px) {
    height: calc(100% - 161px);
  }
}
.order {
  height: 100%;
  &__day {
    padding: 17px 28px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1.07228px solid #e7e7e7;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 23px;
      color: #424242;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #858585;
      position: relative;
      top: 2px;
    }
  }

  &__items {
    width: 100%;
    padding: 0 27px 90px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: absolute;
    bottom: 0;
    top: 85px;
    overflow-y: auto;
    @media (max-height: 680px) {
      top: 64px;
    }

    .item {
      background: #ffffff;
      border-radius: 6px;
      padding: 10px 16px;

      &.disabled {
        opacity: 0.52;
      }

      // No active state
      .item__exstras {
        display: none;
      }

      // Active state
      &.active {
        .check::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          display: block;
          content: '';
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #5c6fd4;
        }

        .item__exstras {
          display: flex;
          margin-top: 11px;
          border-top: 1px solid #e6e6e6;
          padding: 15px 0;

          flex-direction: column;
          align-items: flex-end;
          gap: 12px;

          .extra {
            &.active {
              span::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                display: block;
                content: '';
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #5c6fd4;
              }
            }
            span {
              margin-left: 30px;
              position: relative;
              right: 10px;
              &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                display: block;
                content: '';
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 3px solid #5c6fd4;
              }
            }
          }
        }
      }

      // No active state
      &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info {
          h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #424242;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #858585;
          }
        }

        .check {
          position: relative;
          right: 10px;
          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            display: block;
            content: '';
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 3px solid #5c6fd4;
          }
        }
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    padding: 0 27px;
    width: 100%;
    .order-btn {
      margin-bottom: 21px;
      width: 100%;
      padding: 12px 32px;
      border-radius: 38px;
      border: none;

      font-style: normal;
      font-weight: 640;
      font-size: 17px;
      line-height: 23px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      background: #f94d17;

      display: flex;
      justify-content: center;
      align-items: center;
      font-family: inherit;

      img {
        margin-right: 7px;
      }

      &:disabled {
        background: #bfc2ca;
      }
    }
  }
}
