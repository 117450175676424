.signOutModal {
  top: 50%;
  transform: translate(-50%, -50%) !important;
  padding: 30px;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 106%;
    color: #424242;
    margin-bottom: 10px;
    text-align: center;
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 106%;

    color: #858585;
    margin: 18px 0;
    text-align: center;
  }

  .btn-sign-out {
    width: 100%;

    border: none;
    padding: 12px 42px;

    border-radius: 38px;
    font-style: normal;
    font-weight: 640;
    font-size: 17px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;

    background: #f94d17;

    &.back {
      margin-top: 10px;
      background: #f2f3f6 !important;
      color: #858585;
    }
  }
}
